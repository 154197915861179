// 44100
export const click = [
  0.003875732421875, 0.040740966796875, 0.01495361328125, -0.13763427734375,
  -0.293121337890625, -0.372406005859375, -0.454864501953125,
  -0.533599853515625, -0.49139404296875, -0.32440185546875, -0.092132568359375,
  0.18914794921875, 0.492156982421875, 0.782684326171875, 0.991241455078125,
  0.999969482421875, 0.80950927734375, 0.54974365234375, 0.276763916015625,
  -0.00384521484375, -0.193603515625, -0.22723388671875, -0.182281494140625,
  -0.164306640625, -0.170684814453125, -0.140625, -0.072906494140625,
  -0.019561767578125, 0.027435302734375, 0.10089111328125, 0.16717529296875,
  0.1923828125, 0.1639404296875, 0.080169677734375, -0.017120361328125,
  -0.09857177734375, -0.230255126953125, -0.41845703125, -0.55810546875,
  -0.614013671875, -0.631805419921875, -0.606292724609375, -0.507659912109375,
  -0.335205078125, -0.119354248046875, 0.096527099609375, 0.288818359375,
  0.43060302734375, 0.462158203125, 0.380462646484375, 0.2437744140625,
  0.072845458984375, -0.11822509765625, -0.251312255859375, -0.275909423828125,
  -0.212860107421875, -0.1007080078125, 0.0306396484375, 0.18878173828125,
  0.367034912109375, 0.476043701171875, 0.472412109375, 0.434844970703125,
  0.40545654296875, 0.331512451171875, 0.21844482421875, 0.12188720703125,
  0.045562744140625, -0.04901123046875, -0.16754150390625, -0.256317138671875,
  -0.274749755859375, -0.254364013671875, -0.218048095703125, -0.1334228515625,
  -0.011627197265625, 0.094512939453125, 0.154693603515625, 0.151153564453125,
  0.088470458984375, -0.02178955078125, -0.1988525390625, -0.403839111328125,
  -0.517425537109375, -0.526153564453125, -0.5167236328125, -0.490386962890625,
  -0.397369384765625, -0.25299072265625, -0.093780517578125, 0.07196044921875,
  0.2354736328125, 0.3564453125, 0.37371826171875, 0.285308837890625,
  0.159454345703125, 0.041900634765625, -0.059417724609375, -0.124267578125,
  -0.13616943359375, -0.094757080078125, -0.01275634765625, 0.077789306640625,
  0.18353271484375, 0.3284912109375, 0.44122314453125, 0.448883056640625,
  0.398162841796875, 0.344757080078125, 0.273284912109375, 0.185455322265625,
  0.11468505859375, 0.059326171875, -0.006134033203125, -0.08953857421875,
  -0.15606689453125, -0.161834716796875, -0.12408447265625, -0.067474365234375,
  0.00994873046875, 0.08203125, 0.1168212890625, 0.11431884765625,
  0.075164794921875, 0.0103759765625, -0.0574951171875, -0.15948486328125,
  -0.291259765625, -0.361968994140625, -0.3485107421875, -0.329620361328125,
  -0.31573486328125, -0.259857177734375, -0.16595458984375, -0.0740966796875,
  0.002899169921875, 0.08245849609375, 0.14984130859375, 0.152313232421875,
  0.078826904296875, -0.00775146484375, -0.0723876953125, -0.115234375,
  -0.128662109375, -0.098297119140625, -0.024566650390625, 0.072967529296875,
  0.15106201171875, 0.206512451171875, 0.2708740234375, 0.3072509765625,
  0.264892578125, 0.188140869140625, 0.1339111328125, 0.093658447265625,
  0.05633544921875, 0.038970947265625, 0.038360595703125, 0.036529541015625,
  0.016876220703125, -0.006591796875, -0.0108642578125, -0.000396728515625,
  0.005889892578125, 0.015625, 0.0242919921875, 0.01727294921875,
  -0.009307861328125, -0.044586181640625, -0.0811767578125, -0.114471435546875,
  -0.16552734375, -0.241058349609375, -0.292388916015625, -0.284271240234375,
  -0.257598876953125, -0.235107421875, -0.191619873046875, -0.11810302734375,
  -0.0517578125, -0.0009765625, 0.05401611328125, 0.116455078125, 0.14306640625,
  0.1102294921875, 0.05072021484375, 0.00360107421875, -0.025299072265625,
  -0.042327880859375, -0.03741455078125, -0.0081787109375, 0.047393798828125,
  0.105377197265625, 0.147552490234375, 0.187896728515625, 0.2244873046875,
  0.21685791015625, 0.17486572265625, 0.14764404296875, 0.130615234375,
  0.098876953125, 0.062744140625, 0.040557861328125, 0.01953125,
  -0.01263427734375, -0.043243408203125, -0.049041748046875, -0.02923583984375,
  -0.011566162109375, 0.00128173828125, 0.021636962890625, 0.038055419921875,
  0.042724609375, 0.030517578125, 0.001190185546875, -0.031005859375,
  -0.080078125, -0.1656494140625, -0.24627685546875, -0.262237548828125,
  -0.243316650390625, -0.230010986328125, -0.201507568359375, -0.1363525390625,
  -0.060089111328125, -0.00048828125, 0.054656982421875, 0.11669921875,
  0.15338134765625, 0.1268310546875, 0.059783935546875, -0.005218505859375,
  -0.0584716796875, -0.097625732421875, -0.1109619140625, -0.089599609375,
  -0.0299072265625, 0.03985595703125, 0.095611572265625, 0.154815673828125,
  0.22509765625, 0.25531005859375, 0.2261962890625, 0.181854248046875,
  0.14117431640625, 0.091522216796875, 0.0447998046875, 0.01568603515625,
  -0.00341796875, -0.02874755859375, -0.05712890625, -0.06390380859375,
  -0.04180908203125, -0.0133056640625, 0.009368896484375, 0.030487060546875,
  0.0377197265625, 0.02679443359375, 0.000762939453125, -0.03106689453125,
  -0.064056396484375, -0.10205078125, -0.166900634765625, -0.226837158203125,
  -0.23687744140625, -0.20794677734375, -0.184906005859375, -0.15643310546875,
  -0.106292724609375, -0.047149658203125, 0.00067138671875, 0.03582763671875,
  0.07122802734375, 0.10089111328125, 0.097625732421875, 0.062164306640625,
  0.0185546875, -0.01690673828125, -0.04046630859375, -0.042999267578125,
  -0.02349853515625, 0.01446533203125, 0.0623779296875, 0.102264404296875,
  0.14544677734375, 0.19378662109375, 0.21612548828125, 0.19281005859375,
  0.155059814453125, 0.1221923828125, 0.088043212890625, 0.055877685546875,
  0.030975341796875, 0.0072021484375, -0.0235595703125, -0.05584716796875,
  -0.071929931640625, -0.060791015625, -0.03375244140625, -0.007965087890625,
  0.010986328125, 0.018402099609375, 0.0137939453125, -0.000396728515625,
  -0.022735595703125, -0.0499267578125, -0.0767822265625, -0.10205078125,
  -0.113983154296875, -0.10076904296875, -0.073760986328125, -0.050323486328125,
  -0.033599853515625, -0.016754150390625, -0.00439453125, -0.007476806640625,
  -0.029022216796875, -0.053436279296875, -0.068634033203125,
  -0.074005126953125, -0.075408935546875, -0.06866455078125, -0.05364990234375,
  -0.0313720703125, -0.010955810546875, 0.005859375, 0.02117919921875,
  0.031463623046875, 0.035675048828125, 0.035888671875, 0.03656005859375,
  0.0316162109375, 0.01715087890625, -0.00164794921875, -0.010986328125,
  -0.00860595703125, -0.003875732421875, 0.005401611328125, 0.017486572265625,
  0.03045654296875, 0.04376220703125, 0.058685302734375, 0.071868896484375,
  0.079925537109375, 0.077239990234375, 0.059906005859375, 0.03424072265625,
  0.0086669921875, -0.012359619140625, -0.02142333984375, -0.017822265625,
  -0.012725830078125, -0.002960205078125, 0.009490966796875, 0.02313232421875,
  0.03680419921875, 0.051605224609375, 0.055389404296875, 0.04339599609375,
  0.022705078125, 0.000640869140625, -0.01812744140625, -0.033203125,
  -0.041656494140625, -0.04742431640625, -0.05438232421875, -0.06207275390625,
  -0.053985595703125, -0.024871826171875, 0.013641357421875, 0.04779052734375,
  0.068267822265625, 0.0743408203125, 0.07110595703125, 0.055572509765625,
  0.028106689453125, 0.0003662109375, -0.0196533203125, -0.034027099609375,
  -0.041717529296875, -0.03692626953125, -0.02728271484375, -0.013671875,
  0.005859375, 0.0301513671875, 0.046783447265625, 0.0528564453125,
  0.050079345703125, 0.04290771484375, 0.030853271484375, 0.008331298828125,
  -0.016265869140625, -0.03759765625, -0.054290771484375, -0.06597900390625,
  -0.070343017578125, -0.068572998046875, -0.0574951171875, -0.03997802734375,
  -0.026397705078125, -0.019317626953125, -0.010101318359375, -0.00579833984375,
  -0.01129150390625, -0.023193359375, -0.029998779296875, -0.032012939453125,
  -0.0303955078125, -0.030120849609375, -0.027923583984375, -0.0277099609375,
  -0.026763916015625, -0.019622802734375, -0.004730224609375, 0.00872802734375,
  0.018798828125, 0.024688720703125, 0.02783203125, 0.034332275390625,
  0.035675048828125, 0.030059814453125, 0.024322509765625, 0.02374267578125,
  0.017242431640625, 0.0072021484375, 0.003570556640625, 0.004302978515625,
  0.00482177734375, 0.004241943359375, 0.0050048828125, 0.002471923828125,
  -0.0052490234375, -0.010711669921875, -0.006256103515625, 0.0015869140625,
  0.00335693359375, 0.002532958984375, 0.0003662109375, -0.004791259765625,
  -0.008514404296875, -0.01202392578125, -0.012115478515625, -0.013153076171875,
  -0.0184326171875, -0.02398681640625, -0.016998291015625, 0.00341796875,
  0.025787353515625, 0.040771484375, 0.041839599609375, 0.0360107421875,
  0.030303955078125, 0.0286865234375, 0.031158447265625, 0.036590576171875,
  0.031524658203125, 0.0169677734375, 0.002838134765625, -0.005950927734375,
  -0.009033203125, -0.005279541015625, -0.0006103515625, 0.002532958984375,
  0.006927490234375, 0.009521484375, 0.013885498046875, 0.022308349609375,
  0.03033447265625, 0.029296875, 0.020904541015625, 0.00933837890625,
  -0.002838134765625, -0.0166015625, -0.028106689453125, -0.034088134765625,
  -0.035919189453125, -0.04180908203125, -0.0413818359375, -0.02716064453125,
  -0.008453369140625, 0.003814697265625, 0.010650634765625, 0.014801025390625,
  0.01483154296875, 0.005157470703125, -0.013092041015625, -0.029205322265625,
  -0.037628173828125, -0.044647216796875, -0.048065185546875,
  -0.043182373046875, -0.03277587890625, -0.022735595703125, -0.0091552734375,
  0.0064697265625, 0.017364501953125, 0.020416259765625, 0.0206298828125,
  0.0218505859375, 0.023406982421875, 0.017852783203125, 0.004364013671875,
  -0.007598876953125, -0.01556396484375, -0.020477294921875, -0.0198974609375,
  -0.0107421875, 0.00177001953125, 0.00970458984375, 0.012969970703125,
  0.018585205078125, 0.028106689453125, 0.035186767578125, 0.032684326171875,
  0.02349853515625, 0.0118408203125, -0.002105712890625, -0.01611328125,
  -0.02703857421875, -0.02996826171875, -0.02679443359375, -0.020965576171875,
  -0.012420654296875, 0.00360107421875, 0.0213623046875, 0.030670166015625,
  0.029205322265625, 0.026123046875, 0.023406982421875, 0.016387939453125,
  0.006683349609375, -0.00250244140625, -0.008209228515625, -0.015777587890625,
  -0.024566650390625, -0.020904541015625, -0.010406494140625, 0.00238037109375,
  0.012542724609375, 0.021209716796875, 0.021148681640625, 0.011932373046875,
  0.00250244140625, -9.1552734375e-5, 0.001678466796875, 0.00128173828125,
  -0.0028076171875, -0.005096435546875, -0.00244140625, 0.0, -0.00018310546875,
  0.0029296875, 0.00848388671875, 0.00897216796875, 0.000579833984375,
  -0.0032958984375, -0.0047607421875, -0.005035400390625, -0.0079345703125,
  -0.0081787109375, -0.00689697265625, -0.007568359375, -0.007537841796875,
  -0.00836181640625, -0.005401611328125, -0.00347900390625, -0.003570556640625,
  -0.001953125, 0.003814697265625, 0.017303466796875, 0.026824951171875,
  0.026824951171875, 0.022064208984375, 0.0162353515625, 0.007476806640625,
  -0.01055908203125, -0.026214599609375, -0.036163330078125, -0.035400390625,
  -0.02850341796875, -0.01898193359375, -0.0057373046875, 0.004150390625,
  0.009918212890625, 0.01165771484375, 0.009918212890625, 0.00323486328125,
  -0.003509521484375, -0.0078125, -0.01019287109375, -0.010498046875,
  -0.008941650390625, -0.00494384765625, -0.00054931640625, 0.002044677734375,
  -0.000946044921875, -0.000213623046875, 0.007598876953125, 0.011016845703125,
  0.007476806640625, 0.002197265625, 0.00201416015625, 0.000640869140625,
  -0.0018310546875, -0.006439208984375, -0.006622314453125, -0.003814697265625,
  -0.00067138671875, 0.002197265625, 0.000152587890625, -0.0028076171875,
  -0.005096435546875, -0.0074462890625, -0.0059814453125, 0.00042724609375,
  0.010894775390625, 0.016357421875, 0.015228271484375, 0.011627197265625,
  0.008941650390625, 0.00921630859375, 0.009033203125, 0.00872802734375,
  0.007293701171875, 0.001312255859375, -0.00665283203125, -0.009246826171875,
  -0.00439453125, 0.002838134765625, 0.00537109375, 0.007171630859375,
  0.011077880859375, 0.01513671875, 0.015960693359375, 0.012603759765625,
  0.007293701171875, 0.001251220703125, -0.00445556640625, -0.00701904296875,
  -0.00390625, 0.002410888671875, 0.005889892578125, 0.00506591796875,
  -0.000732421875, -0.009857177734375, -0.018157958984375, -0.019195556640625,
  -0.01690673828125, -0.013397216796875, -0.00958251953125, -0.00439453125,
  -0.001617431640625, -0.001678466796875, -0.006317138671875, -0.01031494140625,
  -0.01220703125, -0.012237548828125, -0.011077880859375, -0.01007080078125,
  -0.004180908203125, 0.00335693359375, 0.010589599609375, 0.011474609375,
  0.00970458984375, 0.008209228515625, 0.004791259765625, -0.000396728515625,
  -0.00640869140625, -0.006072998046875, -0.002288818359375, -0.00164794921875,
  -0.002288818359375, 0.0030517578125, 0.008941650390625, 0.010223388671875,
  0.006591796875, 0.004913330078125, 0.006378173828125, 0.00836181640625,
  0.0096435546875, 0.010589599609375, 0.010711669921875, 0.006500244140625,
  0.000335693359375, -0.004180908203125, -0.00537109375, -0.005859375,
  -0.008056640625, -0.009002685546875, -0.0081787109375, -0.009307861328125,
  -0.00897216796875, -0.0032958984375, 0.00225830078125, 0.004058837890625,
  0.003814697265625, 0.00323486328125, 0.002227783203125, -0.002593994140625,
  -0.00701904296875, -0.012054443359375, -0.013153076171875, -0.012481689453125,
  -0.011932373046875, -0.01239013671875, -0.010894775390625, -0.007171630859375,
  -0.00042724609375, 0.0032958984375, 0.003509521484375, 0.00347900390625,
  0.0050048828125, 0.007568359375, 0.0111083984375, 0.012420654296875,
  0.00994873046875, 0.010040283203125, 0.0123291015625, 0.0146484375,
  0.009124755859375, 0.002685546875, 0.00262451171875, 0.007598876953125,
  0.0125732421875, 0.012847900390625, 0.011383056640625, 0.011505126953125,
  0.009674072265625, 0.005401611328125, 0.00128173828125, 0.000244140625,
  0.00054931640625, -0.00421142578125, -0.0072021484375, -0.007537841796875,
  -0.005096435546875, -0.004425048828125, -0.004425048828125, -0.005126953125,
  -0.008453369140625, -0.01080322265625, -0.00787353515625, -0.005828857421875,
  -0.008636474609375, -0.011810302734375, -0.015655517578125,
  -0.018341064453125, -0.020416259765625, -0.0198974609375, -0.0203857421875,
  -0.01904296875, -0.018310546875, -0.016510009765625, -0.010498046875,
  -0.00372314453125, 0.004791259765625, 0.014739990234375, 0.02178955078125,
  0.023681640625, 0.0216064453125, 0.018463134765625, 0.013885498046875,
  0.007080078125, -0.002197265625, -0.008575439453125, -0.005645751953125,
  0.001708984375, 0.009674072265625, 0.015960693359375, 0.01922607421875,
  0.02325439453125, 0.023529052734375, 0.01971435546875, 0.01019287109375,
  0.002288818359375, -0.003570556640625, -0.006072998046875, -0.009002685546875,
  -0.012176513671875, -0.011871337890625, -0.01104736328125, -0.011932373046875,
  -0.01129150390625, -0.0059814453125, 0.00189208984375, 0.007171630859375,
  0.011505126953125, 0.01239013671875, 0.01251220703125, 0.012481689453125,
  0.00970458984375, 0.002410888671875, -0.006683349609375, -0.015167236328125,
  -0.0198974609375, -0.0235595703125, -0.02313232421875, -0.016937255859375,
  -0.00604248046875, 0.0030517578125, 0.004547119140625, 0.003875732421875,
  0.003997802734375, 0.00445556640625, 0.0023193359375, 0.00177001953125,
  0.001251220703125, -0.002716064453125, -0.011077880859375, -0.015960693359375,
  -0.012420654296875, -0.004180908203125, 0.00341796875, 0.00836181640625,
  0.01055908203125, 0.013214111328125, 0.012420654296875, 0.013092041015625,
  0.013671875, 0.0145263671875, 0.01165771484375, 0.006195068359375,
  0.00115966796875, -9.1552734375e-5, 0.000823974609375, 0.0037841796875,
  0.00299072265625, -0.001556396484375, -0.003509521484375, -0.000457763671875,
  0.00390625, 0.005615234375, 0.003692626953125, -0.00128173828125,
  -0.006805419921875, -0.015350341796875, -0.0208740234375, -0.023895263671875,
  -0.020660400390625, -0.016632080078125, -0.01483154296875, -0.01446533203125,
  -0.01177978515625, -0.004608154296875, 0.002227783203125, 0.003173828125,
  -0.000518798828125, -0.004730224609375, -0.00909423828125, -0.011566162109375,
  -0.00982666015625, -0.00262451171875, 0.001617431640625, 0.001678466796875,
  0.002593994140625, 0.00921630859375, 0.017486572265625, 0.022613525390625,
  0.024169921875, 0.02410888671875, 0.02294921875, 0.018310546875,
  0.010223388671875, 0.005096435546875, 0.00311279296875, 0.001373291015625,
  -0.0013427734375, -0.000640869140625, 0.001861572265625, 0.007049560546875,
  0.01116943359375, 0.011322021484375, 0.007293701171875, 0.000579833984375,
  0.000152587890625, 0.00128173828125, 0.00018310546875, -0.003448486328125,
  -0.00457763671875, -0.00567626953125, -0.0079345703125, -0.009674072265625,
  -0.008270263671875, -0.003997802734375, -0.000885009765625, -0.00213623046875,
  -0.007080078125, -0.01068115234375, -0.0125732421875, -0.014251708984375,
  -0.015899658203125, -0.018585205078125, -0.018798828125, -0.017425537109375,
  -0.01458740234375, -0.009429931640625, -0.00103759765625, 0.008544921875,
  0.015625, 0.021026611328125, 0.022369384765625, 0.0230712890625,
  0.0201416015625, 0.0169677734375, 0.01153564453125, 0.006195068359375,
  -0.002349853515625, -0.009552001953125, -0.012176513671875,
  -0.008514404296875, -0.001312255859375, 0.003631591796875, 0.00982666015625,
  0.015472412109375, 0.020599365234375, 0.02093505859375, 0.01898193359375,
  0.011199951171875, 0.00164794921875, -0.00518798828125, -0.0078125,
  -0.013671875, -0.018585205078125, -0.02294921875, -0.02386474609375,
  -0.0240478515625, -0.02142333984375, -0.01312255859375, -0.00079345703125,
  0.00958251953125, 0.01434326171875, 0.0152587890625, 0.012115478515625,
  0.006866455078125, -0.00164794921875, -0.010009765625, -0.017333984375,
  -0.0230712890625, -0.026519775390625, -0.023040771484375, -0.01513671875,
  -0.006103515625, 0.00146484375, 0.0064697265625, 0.00860595703125,
  0.009521484375, 0.009918212890625, 0.011077880859375, 0.009765625,
  0.007965087890625, 0.00347900390625, 3.0517578125e-5, -0.00335693359375,
  -0.006683349609375, -0.00604248046875, -0.003204345703125, 0.000640869140625,
  0.00189208984375, 0.0028076171875, 0.0047607421875, 0.00714111328125,
  0.01007080078125, 0.0113525390625, 0.008819580078125, 0.006744384765625,
  0.006805419921875, 0.007080078125, 0.006805419921875, 0.00616455078125,
  0.005615234375, 0.003662109375, -0.000396728515625, -0.00244140625,
  -0.001251220703125, 0.002166748046875, 0.00244140625, 0.0029296875,
  0.002471923828125, 3.0517578125e-5, -0.001953125, -0.00494384765625,
  -0.005950927734375, -0.007354736328125, -0.008514404296875, -0.007080078125,
  -0.001617431640625, 0.00286865234375, 0.005859375, 0.00775146484375,
  0.0086669921875, 0.007049560546875, 0.004180908203125, 0.0023193359375,
  -0.000244140625, -0.00042724609375, -0.0032958984375, -0.005462646484375,
  -0.00579833984375, -0.00592041015625, -0.004486083984375, -0.001129150390625,
  0.001068115234375, -0.00103759765625, -0.0030517578125, -0.00323486328125,
  -0.000274658203125, 0.001953125, 0.00286865234375, 0.001953125,
  -0.00091552734375, -0.002105712890625, -0.002349853515625, -0.00250244140625,
  -0.003662109375, -0.004608154296875, -0.005035400390625, -0.007110595703125,
  -0.0096435546875, -0.007720947265625, -0.00372314453125, 0.0008544921875,
  0.0030517578125, 0.003570556640625, 0.003509521484375, 0.003448486328125,
  0.002349853515625, 0.00152587890625, 0.0, -0.001708984375, -0.00274658203125,
  -0.001739501953125, 3.0517578125e-5, 0.001251220703125, 0.000885009765625,
  0.001495361328125, 0.00115966796875, 0.002227783203125, 0.002227783203125,
  0.003875732421875, 0.0042724609375, 0.00347900390625, 0.00177001953125,
  0.001220703125, 0.00146484375, 0.0008544921875, 0.00048828125,
  0.00018310546875, -0.001708984375, -0.003936767578125, -0.0047607421875,
  -0.00225830078125, -0.0003662109375, 0.002197265625, 0.00299072265625,
  0.001983642578125, 0.000732421875, 0.001190185546875, 0.001434326171875,
  -0.00054931640625, -0.002197265625, -0.00286865234375, -0.003204345703125,
  -0.00518798828125, -0.005157470703125, -0.002105712890625, 0.00189208984375,
  0.002593994140625, 0.00201416015625, 0.000823974609375, 0.00140380859375, 0.0,
  -0.00274658203125, -0.00396728515625, -0.00299072265625, -0.003143310546875,
  -0.0030517578125, 0.000213623046875, 0.0047607421875, 0.008087158203125,
  0.00811767578125, 0.00738525390625, 0.006500244140625, 0.005462646484375,
  0.004547119140625, 0.0029296875, 0.00189208984375, -0.001312255859375,
  -0.004180908203125, -0.00579833984375, -0.005859375, -0.0050048828125,
  -0.00244140625, -0.00079345703125, 0.000885009765625, 0.001220703125,
  0.00152587890625, 0.00103759765625, -0.00091552734375, -0.00335693359375,
  -0.006072998046875, -0.0078125, -0.00732421875, -0.005615234375,
  -0.005584716796875, -0.004852294921875, -0.001434326171875, 0.0010986328125,
  0.002227783203125, 0.00238037109375, 0.004119873046875, 0.0091552734375,
  0.009796142578125, 0.008758544921875, 0.00506591796875, 0.004302978515625,
  0.002044677734375, -0.001556396484375, -0.004119873046875, -0.005462646484375,
  -0.006256103515625, -0.00738525390625, -0.004730224609375, 0.00042724609375,
  0.00457763671875, 0.006439208984375, 0.00714111328125, 0.009063720703125,
  0.007080078125, 0.00439453125, 3.0517578125e-5, -0.002288818359375,
  -0.0042724609375, -0.0087890625, -0.01220703125, -0.013702392578125,
  -0.01141357421875, -0.007781982421875, -0.001678466796875, 0.0035400390625,
  0.008331298828125, 0.0101318359375, 0.011993408203125, 0.010498046875,
  0.0089111328125, 0.004852294921875, 0.00262451171875, -0.002105712890625,
  -0.0052490234375, -0.008880615234375, -0.009765625, -0.00848388671875,
  -0.00628662109375, -0.0045166015625, -0.003936767578125, -0.0003662109375,
  0.003204345703125, 0.0050048828125, 0.00531005859375, 0.00555419921875,
  0.006866455078125, 0.00653076171875, 0.00225830078125, -0.00164794921875,
  -0.003936767578125, -0.005645751953125, -0.008880615234375,
  -0.009918212890625, -0.00994873046875, -0.00677490234375, -0.004058837890625,
  -0.001190185546875, 0.0028076171875, 0.00506591796875, 0.0059814453125,
  0.00640869140625, 0.00732421875, 0.0079345703125, 0.00592041015625,
  0.003997802734375, 0.001861572265625, 0.00030517578125, -0.00201416015625,
  -0.003814697265625, -0.001495361328125, -0.000457763671875, 0.00048828125,
  0.001068115234375, 0.00201416015625, 0.00177001953125, 0.00103759765625,
  0.00048828125, -0.001007080078125, -0.0013427734375, -0.00201416015625,
  -0.001922607421875, -0.00128173828125, -0.00048828125, -0.000274658203125,
  -0.00048828125, -0.0001220703125, 0.001007080078125, 0.00079345703125,
  0.001068115234375, 0.001373291015625, 0.002105712890625, -0.0003662109375,
  -0.002716064453125, -0.005615234375, -0.00665283203125, -0.0074462890625,
  -0.0091552734375, -0.008758544921875, -0.006256103515625, -0.002044677734375,
  0.00079345703125, 0.002349853515625, 0.00439453125, 0.006103515625,
  0.005767822265625, 0.002777099609375, 0.001434326171875, 0.001708984375,
  0.001617431640625, 0.00146484375, 0.000640869140625, 0.0015869140625,
  0.001678466796875, 0.00225830078125, 0.00323486328125, 0.003387451171875,
  0.003875732421875, 0.00360107421875, 0.00439453125, 0.003753662109375,
  0.003082275390625, 0.002227783203125, 0.001495361328125, 0.001129150390625,
  0.000244140625, -0.000244140625, -0.00152587890625, -0.001373291015625,
  -0.00238037109375, -0.00274658203125, -0.002777099609375, -0.0020751953125,
  -0.002197265625, -0.00177001953125, -0.001251220703125, -0.0018310546875,
  -0.003570556640625, -0.00469970703125, -0.005126953125, -0.003662109375,
  -0.0030517578125, -0.00238037109375, -0.00250244140625, -0.002105712890625,
  -0.000762939453125, 9.1552734375e-5, 0.000762939453125, 0.002197265625,
  0.003509521484375, 0.003509521484375, 0.002105712890625, 0.00079345703125,
  0.00079345703125, 0.00152587890625, 0.001983642578125, 0.002960205078125,
  0.004547119140625, 0.00543212890625, 0.006011962890625, 0.00555419921875,
  0.005096435546875, 0.00250244140625, 0.000274658203125, -0.003265380859375,
  -0.0047607421875, -0.006500244140625, -0.006378173828125, -0.00604248046875,
  -0.00323486328125, -0.000396728515625, 0.001922607421875, 0.004486083984375,
  0.00592041015625, 0.006927490234375, 0.00531005859375, 0.002899169921875,
  0.000579833984375, -0.0020751953125, -0.005584716796875, -0.008148193359375,
  -0.0113525390625, -0.012939453125, -0.012786865234375, -0.01055908203125,
  -0.006500244140625, -0.003173828125, 0.00048828125, 0.001220703125,
  0.003173828125, 0.003448486328125, 0.004638671875, 0.003448486328125,
  0.002044677734375, 6.103515625e-5, -0.00201416015625, -0.004241943359375,
  -0.00567626953125, -0.0048828125, -0.003082275390625, -0.000518798828125,
  0.001922607421875, 0.004425048828125, 0.007232666015625, 0.010345458984375,
  0.012298583984375, 0.012725830078125, 0.011474609375, 0.00933837890625,
  0.006103515625, 0.003997802734375, 0.0023193359375, 0.00048828125,
  -0.0006103515625, -0.00067138671875, -0.0003662109375, 0.000457763671875,
  0.0009765625, 0.00189208984375, 0.0023193359375, 0.001556396484375,
  -0.000396728515625, -0.002410888671875, -0.00469970703125, -0.006256103515625,
  -0.006591796875, -0.006378173828125, -0.00634765625, -0.00616455078125,
  -0.00555419921875, -0.00384521484375, -0.003021240234375, -0.002593994140625,
  -0.00311279296875, -0.003814697265625, -0.00408935546875, -0.003631591796875,
  -0.00372314453125, -0.00262451171875, -0.001861572265625, -0.00103759765625,
  9.1552734375e-5, 0.00189208984375, 0.003173828125, 0.004638671875,
  0.005828857421875, 0.007080078125, 0.007354736328125, 0.007171630859375,
  0.00726318359375, 0.006378173828125, 0.00482177734375, 0.00103759765625,
  -0.002227783203125, -0.004974365234375, -0.004791259765625,
  -0.004119873046875, -0.002105712890625, -9.1552734375e-5, 0.00244140625,
  0.00445556640625, 0.00543212890625, 0.005462646484375, 0.005401611328125,
  0.00341796875, 0.000457763671875, -0.003570556640625, -0.00640869140625,
  -0.0084228515625, -0.0086669921875, -0.007659912109375, -0.00531005859375,
  -0.003814697265625, -0.0018310546875, -0.00054931640625, 0.00250244140625,
  0.00335693359375, 0.00335693359375, 0.001739501953125, 0.0003662109375,
  -0.0010986328125, -0.0029296875, -0.00384521484375, -0.00347900390625,
  -0.0025634765625, -0.00311279296875, -0.002532958984375, -0.001983642578125,
  0.0008544921875, 0.00164794921875, 0.00341796875, 0.004302978515625,
  0.004425048828125, 0.003021240234375, 0.001373291015625, 0.00048828125,
  0.00042724609375, -0.00079345703125, -0.002471923828125, -0.00372314453125,
  -0.00341796875, -0.002197265625, -0.00018310546875, 0.001312255859375,
  0.004241943359375, 0.0050048828125, 0.00592041015625, 0.005096435546875,
  0.0050048828125, 0.004913330078125, 0.00360107421875, 0.002349853515625,
  0.000213623046875, -0.001251220703125, -0.003387451171875, -0.003692626953125,
  -0.0035400390625, -0.002166748046875, -0.001800537109375, -0.001373291015625,
  -0.000335693359375, 0.0010986328125, 0.001861572265625, 0.001007080078125,
  0.000579833984375, -0.0010986328125, -0.00262451171875, -0.00457763671875,
  -0.00445556640625, -0.00384521484375, -0.002349853515625, -0.0020751953125,
  -0.001129150390625, -0.00048828125, 0.0001220703125, -0.00018310546875,
  0.000457763671875, 0.0, -0.0010986328125, -0.002044677734375,
  -0.00238037109375, -0.001251220703125, -0.00067138671875, -0.00048828125,
  -6.103515625e-5, 0.00091552734375, 0.001739501953125, 0.003082275390625,
  0.003997802734375, 0.005096435546875, 0.005035400390625, 0.003570556640625,
  0.00225830078125, 0.001617431640625, 0.00140380859375, 0.00140380859375,
  0.000518798828125, -3.0517578125e-5, -0.000518798828125, -0.00177001953125,
  -0.001617431640625, -0.00189208984375, -6.103515625e-5, -0.000457763671875,
  3.0517578125e-5, -0.00103759765625, -0.00079345703125, -0.000579833984375,
  -0.00091552734375, -0.0003662109375, -0.001220703125, -0.00146484375,
  -0.002410888671875, -0.00201416015625, -0.0013427734375, -0.0008544921875,
  -0.000823974609375, -0.0010986328125, -0.001251220703125, -0.001434326171875,
  -0.00128173828125, -0.0009765625, 0.000152587890625, -0.000274658203125,
  -0.001220703125, -0.001678466796875, -0.0013427734375, -6.103515625e-5,
  0.000946044921875, 0.001708984375, 0.00250244140625, 0.0025634765625,
  0.002960205078125, 0.00250244140625, 0.002960205078125, 0.00274658203125,
  0.001678466796875, 0.000579833984375, -0.000579833984375, -0.000457763671875,
  -0.000213623046875, 0.0001220703125, 0.000946044921875, 0.00048828125,
  0.0015869140625, 0.00042724609375, 0.0015869140625, 0.001556396484375,
  0.002227783203125, 0.00177001953125, 0.000732421875, -0.00030517578125,
  -0.00030517578125, -0.001556396484375, -0.001190185546875, -0.00244140625,
  -0.001983642578125, -0.00274658203125, -0.002532958984375, -0.0028076171875,
  -0.002166748046875, -0.001251220703125, -0.000732421875, -0.000579833984375,
  -0.000701904296875, -0.001373291015625, -0.00091552734375, -0.001861572265625,
  -0.0023193359375, -0.002593994140625, -0.0035400390625, -0.001617431640625,
  -0.00238037109375, -0.000640869140625, 0.000244140625, 0.00164794921875,
  0.00250244140625, 0.0029296875, 0.003204345703125, 0.00384521484375,
  0.0035400390625, 0.001922607421875, 0.00152587890625, 0.00048828125,
  0.000762939453125, 0.000152587890625, 0.0008544921875, 0.00054931640625,
  0.00103759765625, 0.0003662109375, 0.000396728515625, -0.000152587890625,
  0.000640869140625, 0.000274658203125, 0.000640869140625, 0.00067138671875,
  0.000335693359375, 0.00091552734375, 6.103515625e-5, -0.000274658203125,
  -0.001220703125, -0.00189208984375, -0.003265380859375, -0.00372314453125,
  -0.0040283203125, -0.004150390625, -0.00341796875, -0.002166748046875,
  -0.000762939453125, 0.00115966796875, 0.0018310546875, 0.00250244140625,
  0.00244140625, 0.00201416015625, 0.001129150390625, 0.000518798828125,
  -3.0517578125e-5, -0.000213623046875, -0.0006103515625, -0.001251220703125,
  -0.000885009765625, -0.0010986328125, -9.1552734375e-5, 0.000579833984375,
  0.0013427734375, 0.001983642578125, 0.001617431640625, 0.001983642578125,
  0.00244140625, 0.002471923828125, 0.00286865234375, 0.00225830078125,
  0.001739501953125, 0.00103759765625, -6.103515625e-5, -0.000396728515625,
  -0.00042724609375, -0.00018310546875, -3.0517578125e-5, 0.0,
  -0.000274658203125, 0.000823974609375, 0.000518798828125, 0.00201416015625,
  0.000762939453125, 0.00103759765625, -0.000579833984375, -0.00140380859375,
  -0.002532958984375, -0.0023193359375, -0.00311279296875, -0.0032958984375,
  -0.003997802734375, -0.0037841796875, -0.003021240234375, -0.002471923828125,
  -0.00115966796875, -0.000518798828125, 0.000732421875, 0.0003662109375,
  0.000457763671875, 0.0001220703125, 0.00030517578125, 0.000457763671875,
  -0.000732421875, -0.001251220703125, -0.002105712890625, -0.001312255859375,
  -0.00115966796875, 0.000762939453125, 0.00128173828125, 0.002593994140625,
  0.002410888671875, 0.00201416015625, 0.0015869140625, 0.00146484375,
  0.001251220703125, 0.001220703125, 0.0003662109375, -0.000457763671875,
  -0.000885009765625, -0.001220703125, -0.001068115234375, -0.00048828125,
  0.0001220703125, 0.000274658203125, 0.0001220703125, 0.000457763671875,
  0.000640869140625, 0.001373291015625, 0.001190185546875, 0.00115966796875,
  0.00048828125, 0.0, -0.000762939453125, -0.0010986328125, -0.000946044921875,
  -0.00115966796875, -0.00054931640625, -0.000823974609375, -0.000579833984375,
  0.00048828125, 0.0, 0.00091552734375, 0.00030517578125, -0.000274658203125,
  -0.000579833984375, -0.00146484375, -0.001617431640625, -0.0015869140625,
  -0.001678466796875, -0.00189208984375, -0.001190185546875, -0.001220703125,
  0.000396728515625, 0.00030517578125, 0.0018310546875, 0.001739501953125,
  0.002593994140625, 0.002349853515625, 0.001678466796875, 0.001708984375,
  0.001373291015625, 0.001007080078125, 0.0008544921875, -3.0517578125e-5,
  -0.0001220703125, -0.00048828125, -0.00054931640625, 3.0517578125e-5,
  -3.0517578125e-5, 0.000579833984375, 0.000732421875, 0.00140380859375,
  0.001708984375, 0.0013427734375, 0.0015869140625, 0.00091552734375,
  0.00091552734375, -0.000335693359375, -0.00067138671875, -0.00103759765625,
  -0.00128173828125, -0.001007080078125, -0.0015869140625, -0.000701904296875,
  -0.00048828125, -0.000274658203125, -0.000152587890625, -0.000579833984375,
  -0.000640869140625, -0.001190185546875, -0.0013427734375, -0.0020751953125,
  -0.001708984375, -0.00250244140625, -0.002685546875, -0.00244140625,
  -0.002227783203125, -0.001220703125, -0.001312255859375, -0.00054931640625,
  -0.0006103515625, 9.1552734375e-5, -0.000152587890625, 0.000274658203125,
  0.00054931640625, 0.00146484375, 0.001129150390625, 0.000885009765625,
  0.0009765625, 0.001129150390625, 0.000946044921875, 0.001220703125,
  0.000396728515625, 0.00115966796875, 0.000640869140625, 0.00054931640625,
  0.001068115234375, 0.001556396484375, 0.00189208984375, 0.00177001953125,
  0.00140380859375, 0.001129150390625, 0.000762939453125, -0.0001220703125,
  0.0003662109375, -0.000823974609375, -0.00018310546875, -0.001220703125,
  -0.00177001953125, -0.00146484375, -0.001953125, -0.001495361328125,
  -0.001617431640625, -0.001983642578125, -0.001373291015625, -0.00225830078125,
  -0.001434326171875, -0.001495361328125, -0.000701904296875,
  -0.000457763671875, -0.000640869140625, -0.000396728515625, -0.0003662109375,
  -6.103515625e-5, 0.000457763671875, 9.1552734375e-5, 0.000946044921875,
  0.000885009765625, 0.001190185546875, 0.00115966796875, 0.001434326171875,
  0.00152587890625, 0.00146484375, 0.001312255859375, 0.001190185546875,
  0.000518798828125, 0.000762939453125, 0.00042724609375, 0.000823974609375,
  0.000885009765625, 0.000732421875, 0.001007080078125, 0.000823974609375,
  0.0008544921875, 0.000457763671875, 3.0517578125e-5, 6.103515625e-5,
  -0.000152587890625, -0.000274658203125, -0.000823974609375,
  -0.000762939453125, -0.001007080078125, -0.0006103515625, -0.001129150390625,
  -0.00115966796875, -0.001434326171875, -0.00146484375, -0.001434326171875,
  -0.00189208984375, -0.001220703125, -0.001312255859375, -0.00042724609375,
  -0.0001220703125, -0.000274658203125, 0.00048828125, -3.0517578125e-5,
  0.000244140625, -9.1552734375e-5, -0.000244140625, -3.0517578125e-5,
  -0.000640869140625, -0.000213623046875, -0.000396728515625, 0.00048828125,
  0.0001220703125, 0.00115966796875, 0.000518798828125, 0.001190185546875,
  0.0008544921875, 0.000701904296875, 0.000732421875, 0.000518798828125,
  0.00128173828125, 0.00067138671875, 0.0009765625, 0.000274658203125,
  0.000640869140625, 0.00018310546875, 0.000152587890625, -0.000244140625,
  -0.0006103515625, -0.00048828125, -0.000946044921875, -0.000335693359375,
  -0.000732421875, 0.0001220703125, -0.000244140625, 0.000518798828125,
  -6.103515625e-5, 0.000244140625, -0.000335693359375, -0.000274658203125,
  -0.0006103515625, -0.0006103515625, -0.00115966796875, -0.001190185546875,
  -0.001312255859375, -0.00152587890625, -0.001190185546875, -0.00140380859375,
  -0.000396728515625, -0.000885009765625, 0.000335693359375, 6.103515625e-5,
  0.000457763671875, 0.000762939453125, 0.0003662109375, 0.0010986328125,
  0.0009765625, 0.000885009765625, 0.00079345703125, 0.00067138671875,
  0.000762939453125, 0.000640869140625, 0.000701904296875, 0.000396728515625,
  0.000762939453125, 0.000457763671875, 0.00103759765625, 6.103515625e-5,
  0.000823974609375, 0.0003662109375, 0.000701904296875, 9.1552734375e-5,
  0.000244140625, -0.00079345703125, -0.00018310546875, -0.000823974609375,
  -0.0003662109375, -0.00067138671875, -0.00018310546875, -0.0008544921875,
  -0.000518798828125, -0.000762939453125, -0.000732421875, -0.00042724609375,
  -0.00079345703125, -0.000518798828125, -0.0010986328125, -0.001007080078125,
  -0.001251220703125, -0.00103759765625, -0.00048828125, -0.000640869140625,
  -6.103515625e-5, -0.000244140625, 0.00030517578125, 0.000244140625,
  0.000396728515625, 0.000457763671875, 0.0, 0.00042724609375,
  -0.000152587890625, 0.000152587890625, -0.0001220703125, 3.0517578125e-5,
  0.0001220703125, 3.0517578125e-5, 0.0003662109375, 0.000274658203125,
  0.000579833984375, 0.0006103515625, 0.000762939453125, 0.000762939453125,
  0.000762939453125, 0.000823974609375, 0.00018310546875, 0.000640869140625,
  -0.000244140625, 9.1552734375e-5, -0.000457763671875, -0.000335693359375,
  -0.00054931640625, -0.000518798828125, -0.00018310546875, -0.00018310546875,
  0.000244140625, -6.103515625e-5, -0.0001220703125, 0.00030517578125,
  -0.00042724609375, 0.00018310546875, -0.0008544921875, -0.00018310546875,
  -0.000732421875, -0.000701904296875, -0.00048828125, -0.0008544921875,
  0.00018310546875, -0.0003662109375, 0.0003662109375, 3.0517578125e-5,
  0.0008544921875, 0.0006103515625, 0.00079345703125, 0.0003662109375,
  0.00030517578125, 9.1552734375e-5, -0.000335693359375, -6.103515625e-5,
  -0.00042724609375, -0.0001220703125, -9.1552734375e-5, -0.000457763671875,
  0.00018310546875, -0.000213623046875, 0.000335693359375, 0.000244140625,
  0.00048828125, 0.00030517578125, 0.0003662109375, 0.0001220703125,
  0.0001220703125, 0.0001220703125, -0.000213623046875, -6.103515625e-5,
  -0.000518798828125, -0.00048828125, -0.0003662109375, -0.000640869140625,
  9.1552734375e-5, -0.000152587890625, 0.000152587890625, 0.000152587890625,
  0.0, 0.00018310546875, -0.000152587890625, -6.103515625e-5, -0.00054931640625,
  -0.000244140625, -0.00079345703125, -0.0006103515625, -0.000457763671875,
  -0.000640869140625, 9.1552734375e-5, -0.000152587890625, 0.000213623046875,
  0.0001220703125, 0.00018310546875, 0.000244140625, 6.103515625e-5,
  -0.0001220703125, 0.0, -0.00018310546875, 9.1552734375e-5, -0.000335693359375,
  0.000152587890625, -0.000335693359375, 0.00018310546875, 0.0,
  -3.0517578125e-5, 0.000274658203125, 0.0, 0.0003662109375, -0.00018310546875,
  0.00030517578125, -0.0001220703125, -3.0517578125e-5, 0.0, -0.000152587890625,
  -9.1552734375e-5, -0.000213623046875, -0.000335693359375, 0.0,
  -9.1552734375e-5, 6.103515625e-5, 0.000396728515625, 0.0001220703125,
  0.000518798828125, 9.1552734375e-5, 0.00030517578125, 0.0, 0.000152587890625,
  -0.00018310546875, -0.000213623046875, -0.0001220703125, -0.000701904296875,
  -3.0517578125e-5, -0.000579833984375, -3.0517578125e-5, -0.000274658203125,
  -3.0517578125e-5, -6.103515625e-5, -0.0003662109375, 0.00042724609375,
  -0.000274658203125, 0.00042724609375, -0.000244140625, 6.103515625e-5, 0.0,
  -0.000152587890625, 9.1552734375e-5, -0.0001220703125, -9.1552734375e-5, 0.0,
  -0.000213623046875, 6.103515625e-5, -0.000213623046875, 0.0001220703125,
  3.0517578125e-5, 6.103515625e-5, -6.103515625e-5, 9.1552734375e-5,
  -0.000152587890625, -3.0517578125e-5, 0.0, -0.000213623046875,
  9.1552734375e-5, -0.000244140625, -0.00018310546875, 6.103515625e-5,
  -0.00030517578125, 0.000213623046875, -0.000213623046875, 0.0001220703125,
  -0.000244140625, 0.000335693359375, -0.000518798828125, 0.00042724609375,
  -0.000518798828125, 0.00030517578125, -0.0001220703125, -0.000274658203125,
  0.000213623046875, -0.000396728515625, 0.00042724609375, -0.000518798828125,
  0.000335693359375, -0.000274658203125, 0.0, 0.0, -6.103515625e-5,
  -0.0001220703125, 9.1552734375e-5, -0.000244140625, 3.0517578125e-5,
  0.000152587890625, -0.000396728515625, 0.000274658203125, -0.000335693359375,
];
