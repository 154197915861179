import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Arrangement = {
  __typename?: 'Arrangement';
  dateCreated: Scalars['String'];
  id: Scalars['String'];
  mixes?: Maybe<Array<Mix>>;
  myRecordings?: Maybe<Array<Recording>>;
  name: Scalars['String'];
  piece?: Maybe<Piece>;
  pieceId: Scalars['String'];
  segments?: Maybe<Array<Segment>>;
};

export type Mix = {
  __typename?: 'Mix';
  arrangement?: Maybe<Arrangement>;
  dateCreated: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['String'];
  isPartial: Scalars['Boolean'];
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createArrangement?: Maybe<Arrangement>;
  createMix?: Maybe<Mix>;
  createPiece?: Maybe<Piece>;
  createRandomMix?: Maybe<Mix>;
  createRecording?: Maybe<Recording>;
  createSimpleArrangement?: Maybe<Arrangement>;
  deleteArrangement?: Maybe<Scalars['String']>;
  updateUserSettings?: Maybe<UserSettings>;
};


export type MutationCreateArrangementArgs = {
  name: Scalars['String'];
  pieceId: Scalars['String'];
  segments: Array<SegmentInput>;
};


export type MutationCreateMixArgs = {
  allowPartial?: InputMaybe<Scalars['Boolean']>;
  fill?: InputMaybe<Scalars['Boolean']>;
  recordingIds: Array<Scalars['String']>;
};


export type MutationCreatePieceArgs = {
  name: Scalars['String'];
};


export type MutationCreateRecordingArgs = {
  base64Blob: Scalars['String'];
  sampleRate: Scalars['Int'];
  segmentId: Scalars['String'];
};


export type MutationCreateSimpleArrangementArgs = {
  base64Blob: Scalars['String'];
  name: Scalars['String'];
  pieceId: Scalars['String'];
};


export type MutationDeleteArrangementArgs = {
  arrangementId: Scalars['String'];
};


export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsInput;
  userId: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  duration: Scalars['Float'];
  lyric?: Maybe<Scalars['String']>;
  midi: Scalars['Int'];
  time: Scalars['Float'];
  velocity: Scalars['Float'];
};

export type NoteInput = {
  duration: Scalars['Float'];
  lyric?: InputMaybe<Scalars['String']>;
  midi: Scalars['Int'];
  time: Scalars['Float'];
  velocity: Scalars['Float'];
};

export type Piece = {
  __typename?: 'Piece';
  arrangements?: Maybe<Array<Arrangement>>;
  dateCreated: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getAllPieces?: Maybe<Array<Piece>>;
  getArrangementById?: Maybe<Arrangement>;
  getArrangementByIds?: Maybe<Array<Maybe<Arrangement>>>;
  getArrangementByRecordingId?: Maybe<Arrangement>;
  getArrangementsByPieceId?: Maybe<Array<Arrangement>>;
  getMixesByArrangementId?: Maybe<Array<Mix>>;
  getMixesByRecordingId?: Maybe<Array<Mix>>;
  getMixesWithMe: Array<Mix>;
  getMyUserSettings: UserSettings;
  getNextSegment?: Maybe<Segment>;
  getPieceById?: Maybe<Piece>;
  getRecordingById?: Maybe<Recording>;
  getRecordingsByIds?: Maybe<Array<Maybe<Recording>>>;
  getRecordingsByUserId?: Maybe<Array<Recording>>;
  getSegmentById?: Maybe<Segment>;
  getSegmentsByArrangementId?: Maybe<Array<Segment>>;
};


export type QueryGetArrangementByIdArgs = {
  arrangementId: Scalars['String'];
};


export type QueryGetArrangementByIdsArgs = {
  arrangementIds: Array<Scalars['String']>;
};


export type QueryGetArrangementByRecordingIdArgs = {
  recordingId: Scalars['String'];
};


export type QueryGetArrangementsByPieceIdArgs = {
  pieceId: Scalars['String'];
};


export type QueryGetMixesByArrangementIdArgs = {
  arrangementId: Scalars['String'];
};


export type QueryGetMixesByRecordingIdArgs = {
  recordingId: Scalars['String'];
};


export type QueryGetPieceByIdArgs = {
  pieceId: Scalars['String'];
};


export type QueryGetRecordingByIdArgs = {
  recordingId: Scalars['String'];
};


export type QueryGetRecordingsByIdsArgs = {
  recordingIds: Array<Scalars['String']>;
};


export type QueryGetRecordingsByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetSegmentByIdArgs = {
  segmentId: Scalars['String'];
};


export type QueryGetSegmentsByArrangementIdArgs = {
  arrangementId: Scalars['String'];
};

export type Recording = {
  __typename?: 'Recording';
  dateCreated: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['String'];
  objectKey: Scalars['String'];
  segmentId: Scalars['String'];
  url: Scalars['String'];
};

export type Segment = {
  __typename?: 'Segment';
  arrangementId: Scalars['String'];
  bpm: Scalars['Float'];
  dateCreated: Scalars['String'];
  difficulty?: Maybe<Scalars['Int']>;
  highestNote: Scalars['Int'];
  id: Scalars['String'];
  lowestNote: Scalars['Int'];
  notes: Array<Note>;
  offset: Scalars['Float'];
};

export type SegmentInput = {
  bpm: Scalars['Float'];
  difficulty?: InputMaybe<Scalars['Int']>;
  notes: Array<NoteInput>;
  offset: Scalars['Float'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  metronomeOnRecord?: Maybe<Scalars['Boolean']>;
  metronomeOnRecordingPlay?: Maybe<Scalars['Boolean']>;
  metronomeOnSegmentPlay?: Maybe<Scalars['Boolean']>;
  notesOnRecord?: Maybe<Scalars['Boolean']>;
  notesOnRecordingPlay?: Maybe<Scalars['Boolean']>;
  notesOnSegmentPlay?: Maybe<Scalars['Boolean']>;
};

export type UserSettingsInput = {
  metronomeOnRecord?: InputMaybe<Scalars['Boolean']>;
  metronomeOnRecordingPlay?: InputMaybe<Scalars['Boolean']>;
  metronomeOnSegmentPlay?: InputMaybe<Scalars['Boolean']>;
  notesOnRecord?: InputMaybe<Scalars['Boolean']>;
  notesOnRecordingPlay?: InputMaybe<Scalars['Boolean']>;
  notesOnSegmentPlay?: InputMaybe<Scalars['Boolean']>;
};

export type CreateSimpleArrangementMutationVariables = Exact<{
  pieceId: Scalars['String'];
  name: Scalars['String'];
  base64Blob: Scalars['String'];
}>;


export type CreateSimpleArrangementMutation = { __typename?: 'Mutation', createSimpleArrangement?: { __typename?: 'Arrangement', id: string, name: string, dateCreated: string } | null };

export type DeleteArrangementMutationVariables = Exact<{
  arrangementId: Scalars['String'];
}>;


export type DeleteArrangementMutation = { __typename?: 'Mutation', deleteArrangement?: string | null };

export type GetArrangementsQueryVariables = Exact<{
  pieceId: Scalars['String'];
}>;


export type GetArrangementsQuery = { __typename?: 'Query', getArrangementsByPieceId?: Array<{ __typename?: 'Arrangement', id: string, dateCreated: string }> | null };

export type GetAllPiecesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPiecesQuery = { __typename?: 'Query', getAllPieces?: Array<{ __typename?: 'Piece', dateCreated: string, name?: string | null, id: string }> | null };

export type GetArrangementQueryVariables = Exact<{
  arrangementId: Scalars['String'];
}>;


export type GetArrangementQuery = { __typename?: 'Query', getArrangementById?: { __typename?: 'Arrangement', id: string, pieceId: string, name: string, dateCreated: string, mixes?: Array<{ __typename?: 'Mix', id: string, url: string, isPartial: boolean, duration: number, dateCreated: string }> | null } | null };

export type GetSegmentsQueryVariables = Exact<{
  arrangementId: Scalars['String'];
}>;


export type GetSegmentsQuery = { __typename?: 'Query', getSegmentsByArrangementId?: Array<{ __typename?: 'Segment', id: string, bpm: number, arrangementId: string, difficulty?: number | null, offset: number, highestNote: number, lowestNote: number, dateCreated: string, notes: Array<{ __typename?: 'Note', time: number, midi: number, duration: number, velocity: number, lyric?: string | null }> }> | null };

export type CreateMixMutationVariables = Exact<{
  recordingIds: Array<Scalars['String']> | Scalars['String'];
  allowPartial?: InputMaybe<Scalars['Boolean']>;
  fill?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateMixMutation = { __typename?: 'Mutation', createMix?: { __typename?: 'Mix', id: string, url: string, isPartial: boolean, duration: number, dateCreated: string } | null };

export type GetArrangementForMixingQueryVariables = Exact<{
  recordingId: Scalars['String'];
}>;


export type GetArrangementForMixingQuery = { __typename?: 'Query', getArrangementByRecordingId?: { __typename?: 'Arrangement', id: string, name: string, dateCreated: string, piece?: { __typename?: 'Piece', id: string, name?: string | null, dateCreated: string } | null, segments?: Array<{ __typename?: 'Segment', id: string, offset: number }> | null, myRecordings?: Array<{ __typename?: 'Recording', id: string, duration: number, segmentId: string, objectKey: string, dateCreated: string, url: string }> | null } | null };

export type GetMixesWithMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMixesWithMeQuery = { __typename?: 'Query', getMixesWithMe: Array<{ __typename?: 'Mix', id: string, url: string, duration: number, dateCreated: string, arrangement?: { __typename?: 'Arrangement', name: string, piece?: { __typename?: 'Piece', name?: string | null } | null } | null }> };

export type GetRecordingsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetRecordingsQuery = { __typename?: 'Query', getRecordingsByUserId?: Array<{ __typename?: 'Recording', id: string, segmentId: string, objectKey: string, duration: number, url: string, dateCreated: string }> | null };

export type GetPieceQueryVariables = Exact<{
  pieceId: Scalars['String'];
}>;


export type GetPieceQuery = { __typename?: 'Query', getPieceById?: { __typename?: 'Piece', id: string, name?: string | null, dateCreated: string, arrangements?: Array<{ __typename?: 'Arrangement', id: string, name: string, dateCreated: string }> | null } | null };

export type CreatePieceMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreatePieceMutation = { __typename?: 'Mutation', createPiece?: { __typename?: 'Piece', id: string, name?: string | null, dateCreated: string } | null };

export type GetNextSegmentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNextSegmentQuery = { __typename?: 'Query', getNextSegment?: { __typename?: 'Segment', id: string, arrangementId: string, difficulty?: number | null, bpm: number, offset: number, highestNote: number, lowestNote: number, dateCreated: string, notes: Array<{ __typename?: 'Note', velocity: number, time: number, midi: number, duration: number, lyric?: string | null }> } | null };

export type GetSegmentQueryVariables = Exact<{
  segmentId: Scalars['String'];
}>;


export type GetSegmentQuery = { __typename?: 'Query', getSegmentById?: { __typename?: 'Segment', id: string, arrangementId: string, difficulty?: number | null, bpm: number, offset: number, highestNote: number, lowestNote: number, dateCreated: string, notes: Array<{ __typename?: 'Note', velocity: number, midi: number, time: number, duration: number, lyric?: string | null }> } | null };

export type CreateArrangementMutationVariables = Exact<{
  pieceId: Scalars['String'];
  name: Scalars['String'];
  segments: Array<SegmentInput> | SegmentInput;
}>;


export type CreateArrangementMutation = { __typename?: 'Mutation', createArrangement?: { __typename?: 'Arrangement', id: string, name: string, dateCreated: string } | null };

export type GetMySettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMySettingsQuery = { __typename?: 'Query', getMyUserSettings: { __typename?: 'UserSettings', metronomeOnSegmentPlay?: boolean | null, notesOnSegmentPlay?: boolean | null, metronomeOnRecord?: boolean | null, notesOnRecord?: boolean | null, metronomeOnRecordingPlay?: boolean | null, notesOnRecordingPlay?: boolean | null } };

export type UpdateUserSettingsMutationVariables = Exact<{
  userId: Scalars['String'];
  input: UserSettingsInput;
}>;


export type UpdateUserSettingsMutation = { __typename?: 'Mutation', updateUserSettings?: { __typename?: 'UserSettings', metronomeOnSegmentPlay?: boolean | null, notesOnSegmentPlay?: boolean | null, metronomeOnRecord?: boolean | null, notesOnRecord?: boolean | null, metronomeOnRecordingPlay?: boolean | null, notesOnRecordingPlay?: boolean | null } | null };

export type CreateRecordingMutationVariables = Exact<{
  base64Blob: Scalars['String'];
  segmentId: Scalars['String'];
  sampleRate: Scalars['Int'];
}>;


export type CreateRecordingMutation = { __typename?: 'Mutation', createRecording?: { __typename?: 'Recording', id: string, objectKey: string, url: string, duration: number, segmentId: string, dateCreated: string } | null };


export const CreateSimpleArrangementDocument = gql`
    mutation CreateSimpleArrangement($pieceId: String!, $name: String!, $base64Blob: String!) {
  createSimpleArrangement(pieceId: $pieceId, name: $name, base64Blob: $base64Blob) {
    id
    name
    dateCreated
  }
}
    `;
export type CreateSimpleArrangementMutationFn = Apollo.MutationFunction<CreateSimpleArrangementMutation, CreateSimpleArrangementMutationVariables>;

/**
 * __useCreateSimpleArrangementMutation__
 *
 * To run a mutation, you first call `useCreateSimpleArrangementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimpleArrangementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimpleArrangementMutation, { data, loading, error }] = useCreateSimpleArrangementMutation({
 *   variables: {
 *      pieceId: // value for 'pieceId'
 *      name: // value for 'name'
 *      base64Blob: // value for 'base64Blob'
 *   },
 * });
 */
export function useCreateSimpleArrangementMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimpleArrangementMutation, CreateSimpleArrangementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimpleArrangementMutation, CreateSimpleArrangementMutationVariables>(CreateSimpleArrangementDocument, options);
      }
export type CreateSimpleArrangementMutationHookResult = ReturnType<typeof useCreateSimpleArrangementMutation>;
export type CreateSimpleArrangementMutationResult = Apollo.MutationResult<CreateSimpleArrangementMutation>;
export type CreateSimpleArrangementMutationOptions = Apollo.BaseMutationOptions<CreateSimpleArrangementMutation, CreateSimpleArrangementMutationVariables>;
export const DeleteArrangementDocument = gql`
    mutation DeleteArrangement($arrangementId: String!) {
  deleteArrangement(arrangementId: $arrangementId)
}
    `;
export type DeleteArrangementMutationFn = Apollo.MutationFunction<DeleteArrangementMutation, DeleteArrangementMutationVariables>;

/**
 * __useDeleteArrangementMutation__
 *
 * To run a mutation, you first call `useDeleteArrangementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArrangementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArrangementMutation, { data, loading, error }] = useDeleteArrangementMutation({
 *   variables: {
 *      arrangementId: // value for 'arrangementId'
 *   },
 * });
 */
export function useDeleteArrangementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArrangementMutation, DeleteArrangementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArrangementMutation, DeleteArrangementMutationVariables>(DeleteArrangementDocument, options);
      }
export type DeleteArrangementMutationHookResult = ReturnType<typeof useDeleteArrangementMutation>;
export type DeleteArrangementMutationResult = Apollo.MutationResult<DeleteArrangementMutation>;
export type DeleteArrangementMutationOptions = Apollo.BaseMutationOptions<DeleteArrangementMutation, DeleteArrangementMutationVariables>;
export const GetArrangementsDocument = gql`
    query GetArrangements($pieceId: String!) {
  getArrangementsByPieceId(pieceId: $pieceId) {
    id
    dateCreated
  }
}
    `;

/**
 * __useGetArrangementsQuery__
 *
 * To run a query within a React component, call `useGetArrangementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrangementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrangementsQuery({
 *   variables: {
 *      pieceId: // value for 'pieceId'
 *   },
 * });
 */
export function useGetArrangementsQuery(baseOptions: Apollo.QueryHookOptions<GetArrangementsQuery, GetArrangementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArrangementsQuery, GetArrangementsQueryVariables>(GetArrangementsDocument, options);
      }
export function useGetArrangementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArrangementsQuery, GetArrangementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArrangementsQuery, GetArrangementsQueryVariables>(GetArrangementsDocument, options);
        }
export type GetArrangementsQueryHookResult = ReturnType<typeof useGetArrangementsQuery>;
export type GetArrangementsLazyQueryHookResult = ReturnType<typeof useGetArrangementsLazyQuery>;
export type GetArrangementsQueryResult = Apollo.QueryResult<GetArrangementsQuery, GetArrangementsQueryVariables>;
export const GetAllPiecesDocument = gql`
    query GetAllPieces {
  getAllPieces {
    dateCreated
    name
    id
  }
}
    `;

/**
 * __useGetAllPiecesQuery__
 *
 * To run a query within a React component, call `useGetAllPiecesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPiecesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPiecesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPiecesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPiecesQuery, GetAllPiecesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPiecesQuery, GetAllPiecesQueryVariables>(GetAllPiecesDocument, options);
      }
export function useGetAllPiecesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPiecesQuery, GetAllPiecesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPiecesQuery, GetAllPiecesQueryVariables>(GetAllPiecesDocument, options);
        }
export type GetAllPiecesQueryHookResult = ReturnType<typeof useGetAllPiecesQuery>;
export type GetAllPiecesLazyQueryHookResult = ReturnType<typeof useGetAllPiecesLazyQuery>;
export type GetAllPiecesQueryResult = Apollo.QueryResult<GetAllPiecesQuery, GetAllPiecesQueryVariables>;
export const GetArrangementDocument = gql`
    query GetArrangement($arrangementId: String!) {
  getArrangementById(arrangementId: $arrangementId) {
    id
    pieceId
    name
    mixes {
      id
      url
      isPartial
      duration
      dateCreated
    }
    dateCreated
  }
}
    `;

/**
 * __useGetArrangementQuery__
 *
 * To run a query within a React component, call `useGetArrangementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrangementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrangementQuery({
 *   variables: {
 *      arrangementId: // value for 'arrangementId'
 *   },
 * });
 */
export function useGetArrangementQuery(baseOptions: Apollo.QueryHookOptions<GetArrangementQuery, GetArrangementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArrangementQuery, GetArrangementQueryVariables>(GetArrangementDocument, options);
      }
export function useGetArrangementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArrangementQuery, GetArrangementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArrangementQuery, GetArrangementQueryVariables>(GetArrangementDocument, options);
        }
export type GetArrangementQueryHookResult = ReturnType<typeof useGetArrangementQuery>;
export type GetArrangementLazyQueryHookResult = ReturnType<typeof useGetArrangementLazyQuery>;
export type GetArrangementQueryResult = Apollo.QueryResult<GetArrangementQuery, GetArrangementQueryVariables>;
export const GetSegmentsDocument = gql`
    query GetSegments($arrangementId: String!) {
  getSegmentsByArrangementId(arrangementId: $arrangementId) {
    id
    bpm
    arrangementId
    difficulty
    notes {
      time
      midi
      duration
      velocity
      lyric
    }
    offset
    highestNote
    lowestNote
    dateCreated
  }
}
    `;

/**
 * __useGetSegmentsQuery__
 *
 * To run a query within a React component, call `useGetSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentsQuery({
 *   variables: {
 *      arrangementId: // value for 'arrangementId'
 *   },
 * });
 */
export function useGetSegmentsQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentsQuery, GetSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegmentsDocument, options);
      }
export function useGetSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentsQuery, GetSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegmentsDocument, options);
        }
export type GetSegmentsQueryHookResult = ReturnType<typeof useGetSegmentsQuery>;
export type GetSegmentsLazyQueryHookResult = ReturnType<typeof useGetSegmentsLazyQuery>;
export type GetSegmentsQueryResult = Apollo.QueryResult<GetSegmentsQuery, GetSegmentsQueryVariables>;
export const CreateMixDocument = gql`
    mutation CreateMix($recordingIds: [String!]!, $allowPartial: Boolean, $fill: Boolean) {
  createMix(recordingIds: $recordingIds, allowPartial: $allowPartial, fill: $fill) {
    id
    url
    isPartial
    duration
    dateCreated
  }
}
    `;
export type CreateMixMutationFn = Apollo.MutationFunction<CreateMixMutation, CreateMixMutationVariables>;

/**
 * __useCreateMixMutation__
 *
 * To run a mutation, you first call `useCreateMixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMixMutation, { data, loading, error }] = useCreateMixMutation({
 *   variables: {
 *      recordingIds: // value for 'recordingIds'
 *      allowPartial: // value for 'allowPartial'
 *      fill: // value for 'fill'
 *   },
 * });
 */
export function useCreateMixMutation(baseOptions?: Apollo.MutationHookOptions<CreateMixMutation, CreateMixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMixMutation, CreateMixMutationVariables>(CreateMixDocument, options);
      }
export type CreateMixMutationHookResult = ReturnType<typeof useCreateMixMutation>;
export type CreateMixMutationResult = Apollo.MutationResult<CreateMixMutation>;
export type CreateMixMutationOptions = Apollo.BaseMutationOptions<CreateMixMutation, CreateMixMutationVariables>;
export const GetArrangementForMixingDocument = gql`
    query GetArrangementForMixing($recordingId: String!) {
  getArrangementByRecordingId(recordingId: $recordingId) {
    id
    name
    dateCreated
    piece {
      id
      name
      dateCreated
    }
    segments {
      id
      offset
    }
    myRecordings {
      id
      duration
      segmentId
      objectKey
      dateCreated
      url
    }
  }
}
    `;

/**
 * __useGetArrangementForMixingQuery__
 *
 * To run a query within a React component, call `useGetArrangementForMixingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrangementForMixingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrangementForMixingQuery({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useGetArrangementForMixingQuery(baseOptions: Apollo.QueryHookOptions<GetArrangementForMixingQuery, GetArrangementForMixingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArrangementForMixingQuery, GetArrangementForMixingQueryVariables>(GetArrangementForMixingDocument, options);
      }
export function useGetArrangementForMixingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArrangementForMixingQuery, GetArrangementForMixingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArrangementForMixingQuery, GetArrangementForMixingQueryVariables>(GetArrangementForMixingDocument, options);
        }
export type GetArrangementForMixingQueryHookResult = ReturnType<typeof useGetArrangementForMixingQuery>;
export type GetArrangementForMixingLazyQueryHookResult = ReturnType<typeof useGetArrangementForMixingLazyQuery>;
export type GetArrangementForMixingQueryResult = Apollo.QueryResult<GetArrangementForMixingQuery, GetArrangementForMixingQueryVariables>;
export const GetMixesWithMeDocument = gql`
    query GetMixesWithMe {
  getMixesWithMe {
    id
    url
    duration
    dateCreated
    arrangement {
      name
      piece {
        name
      }
    }
  }
}
    `;

/**
 * __useGetMixesWithMeQuery__
 *
 * To run a query within a React component, call `useGetMixesWithMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMixesWithMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMixesWithMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMixesWithMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMixesWithMeQuery, GetMixesWithMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMixesWithMeQuery, GetMixesWithMeQueryVariables>(GetMixesWithMeDocument, options);
      }
export function useGetMixesWithMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMixesWithMeQuery, GetMixesWithMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMixesWithMeQuery, GetMixesWithMeQueryVariables>(GetMixesWithMeDocument, options);
        }
export type GetMixesWithMeQueryHookResult = ReturnType<typeof useGetMixesWithMeQuery>;
export type GetMixesWithMeLazyQueryHookResult = ReturnType<typeof useGetMixesWithMeLazyQuery>;
export type GetMixesWithMeQueryResult = Apollo.QueryResult<GetMixesWithMeQuery, GetMixesWithMeQueryVariables>;
export const GetRecordingsDocument = gql`
    query GetRecordings($userId: String!) {
  getRecordingsByUserId(userId: $userId) {
    id
    segmentId
    objectKey
    duration
    url
    dateCreated
  }
}
    `;

/**
 * __useGetRecordingsQuery__
 *
 * To run a query within a React component, call `useGetRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRecordingsQuery(baseOptions: Apollo.QueryHookOptions<GetRecordingsQuery, GetRecordingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecordingsQuery, GetRecordingsQueryVariables>(GetRecordingsDocument, options);
      }
export function useGetRecordingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecordingsQuery, GetRecordingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecordingsQuery, GetRecordingsQueryVariables>(GetRecordingsDocument, options);
        }
export type GetRecordingsQueryHookResult = ReturnType<typeof useGetRecordingsQuery>;
export type GetRecordingsLazyQueryHookResult = ReturnType<typeof useGetRecordingsLazyQuery>;
export type GetRecordingsQueryResult = Apollo.QueryResult<GetRecordingsQuery, GetRecordingsQueryVariables>;
export const GetPieceDocument = gql`
    query GetPiece($pieceId: String!) {
  getPieceById(pieceId: $pieceId) {
    id
    name
    arrangements {
      id
      name
      dateCreated
    }
    dateCreated
  }
}
    `;

/**
 * __useGetPieceQuery__
 *
 * To run a query within a React component, call `useGetPieceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPieceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPieceQuery({
 *   variables: {
 *      pieceId: // value for 'pieceId'
 *   },
 * });
 */
export function useGetPieceQuery(baseOptions: Apollo.QueryHookOptions<GetPieceQuery, GetPieceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPieceQuery, GetPieceQueryVariables>(GetPieceDocument, options);
      }
export function useGetPieceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPieceQuery, GetPieceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPieceQuery, GetPieceQueryVariables>(GetPieceDocument, options);
        }
export type GetPieceQueryHookResult = ReturnType<typeof useGetPieceQuery>;
export type GetPieceLazyQueryHookResult = ReturnType<typeof useGetPieceLazyQuery>;
export type GetPieceQueryResult = Apollo.QueryResult<GetPieceQuery, GetPieceQueryVariables>;
export const CreatePieceDocument = gql`
    mutation CreatePiece($name: String!) {
  createPiece(name: $name) {
    id
    name
    dateCreated
  }
}
    `;
export type CreatePieceMutationFn = Apollo.MutationFunction<CreatePieceMutation, CreatePieceMutationVariables>;

/**
 * __useCreatePieceMutation__
 *
 * To run a mutation, you first call `useCreatePieceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePieceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPieceMutation, { data, loading, error }] = useCreatePieceMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePieceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePieceMutation, CreatePieceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePieceMutation, CreatePieceMutationVariables>(CreatePieceDocument, options);
      }
export type CreatePieceMutationHookResult = ReturnType<typeof useCreatePieceMutation>;
export type CreatePieceMutationResult = Apollo.MutationResult<CreatePieceMutation>;
export type CreatePieceMutationOptions = Apollo.BaseMutationOptions<CreatePieceMutation, CreatePieceMutationVariables>;
export const GetNextSegmentDocument = gql`
    query GetNextSegment {
  getNextSegment {
    id
    arrangementId
    difficulty
    bpm
    notes {
      velocity
      time
      midi
      duration
      lyric
    }
    offset
    highestNote
    lowestNote
    dateCreated
  }
}
    `;

/**
 * __useGetNextSegmentQuery__
 *
 * To run a query within a React component, call `useGetNextSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextSegmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextSegmentQuery(baseOptions?: Apollo.QueryHookOptions<GetNextSegmentQuery, GetNextSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextSegmentQuery, GetNextSegmentQueryVariables>(GetNextSegmentDocument, options);
      }
export function useGetNextSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextSegmentQuery, GetNextSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextSegmentQuery, GetNextSegmentQueryVariables>(GetNextSegmentDocument, options);
        }
export type GetNextSegmentQueryHookResult = ReturnType<typeof useGetNextSegmentQuery>;
export type GetNextSegmentLazyQueryHookResult = ReturnType<typeof useGetNextSegmentLazyQuery>;
export type GetNextSegmentQueryResult = Apollo.QueryResult<GetNextSegmentQuery, GetNextSegmentQueryVariables>;
export const GetSegmentDocument = gql`
    query GetSegment($segmentId: String!) {
  getSegmentById(segmentId: $segmentId) {
    id
    arrangementId
    difficulty
    bpm
    notes {
      velocity
      midi
      time
      duration
      lyric
    }
    offset
    highestNote
    lowestNote
    dateCreated
  }
}
    `;

/**
 * __useGetSegmentQuery__
 *
 * To run a query within a React component, call `useGetSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentQuery({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *   },
 * });
 */
export function useGetSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentQuery, GetSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentQuery, GetSegmentQueryVariables>(GetSegmentDocument, options);
      }
export function useGetSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentQuery, GetSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentQuery, GetSegmentQueryVariables>(GetSegmentDocument, options);
        }
export type GetSegmentQueryHookResult = ReturnType<typeof useGetSegmentQuery>;
export type GetSegmentLazyQueryHookResult = ReturnType<typeof useGetSegmentLazyQuery>;
export type GetSegmentQueryResult = Apollo.QueryResult<GetSegmentQuery, GetSegmentQueryVariables>;
export const CreateArrangementDocument = gql`
    mutation CreateArrangement($pieceId: String!, $name: String!, $segments: [SegmentInput!]!) {
  createArrangement(pieceId: $pieceId, name: $name, segments: $segments) {
    id
    name
    dateCreated
  }
}
    `;
export type CreateArrangementMutationFn = Apollo.MutationFunction<CreateArrangementMutation, CreateArrangementMutationVariables>;

/**
 * __useCreateArrangementMutation__
 *
 * To run a mutation, you first call `useCreateArrangementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArrangementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArrangementMutation, { data, loading, error }] = useCreateArrangementMutation({
 *   variables: {
 *      pieceId: // value for 'pieceId'
 *      name: // value for 'name'
 *      segments: // value for 'segments'
 *   },
 * });
 */
export function useCreateArrangementMutation(baseOptions?: Apollo.MutationHookOptions<CreateArrangementMutation, CreateArrangementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArrangementMutation, CreateArrangementMutationVariables>(CreateArrangementDocument, options);
      }
export type CreateArrangementMutationHookResult = ReturnType<typeof useCreateArrangementMutation>;
export type CreateArrangementMutationResult = Apollo.MutationResult<CreateArrangementMutation>;
export type CreateArrangementMutationOptions = Apollo.BaseMutationOptions<CreateArrangementMutation, CreateArrangementMutationVariables>;
export const GetMySettingsDocument = gql`
    query GetMySettings {
  getMyUserSettings {
    metronomeOnSegmentPlay
    notesOnSegmentPlay
    metronomeOnRecord
    notesOnRecord
    metronomeOnRecordingPlay
    notesOnRecordingPlay
  }
}
    `;

/**
 * __useGetMySettingsQuery__
 *
 * To run a query within a React component, call `useGetMySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetMySettingsQuery, GetMySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMySettingsQuery, GetMySettingsQueryVariables>(GetMySettingsDocument, options);
      }
export function useGetMySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMySettingsQuery, GetMySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMySettingsQuery, GetMySettingsQueryVariables>(GetMySettingsDocument, options);
        }
export type GetMySettingsQueryHookResult = ReturnType<typeof useGetMySettingsQuery>;
export type GetMySettingsLazyQueryHookResult = ReturnType<typeof useGetMySettingsLazyQuery>;
export type GetMySettingsQueryResult = Apollo.QueryResult<GetMySettingsQuery, GetMySettingsQueryVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($userId: String!, $input: UserSettingsInput!) {
  updateUserSettings(userId: $userId, input: $input) {
    metronomeOnSegmentPlay
    notesOnSegmentPlay
    metronomeOnRecord
    notesOnRecord
    metronomeOnRecordingPlay
    notesOnRecordingPlay
  }
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const CreateRecordingDocument = gql`
    mutation CreateRecording($base64Blob: String!, $segmentId: String!, $sampleRate: Int!) {
  createRecording(
    base64Blob: $base64Blob
    segmentId: $segmentId
    sampleRate: $sampleRate
  ) {
    id
    objectKey
    url
    duration
    segmentId
    dateCreated
  }
}
    `;
export type CreateRecordingMutationFn = Apollo.MutationFunction<CreateRecordingMutation, CreateRecordingMutationVariables>;

/**
 * __useCreateRecordingMutation__
 *
 * To run a mutation, you first call `useCreateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingMutation, { data, loading, error }] = useCreateRecordingMutation({
 *   variables: {
 *      base64Blob: // value for 'base64Blob'
 *      segmentId: // value for 'segmentId'
 *      sampleRate: // value for 'sampleRate'
 *   },
 * });
 */
export function useCreateRecordingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordingMutation, CreateRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordingMutation, CreateRecordingMutationVariables>(CreateRecordingDocument, options);
      }
export type CreateRecordingMutationHookResult = ReturnType<typeof useCreateRecordingMutation>;
export type CreateRecordingMutationResult = Apollo.MutationResult<CreateRecordingMutation>;
export type CreateRecordingMutationOptions = Apollo.BaseMutationOptions<CreateRecordingMutation, CreateRecordingMutationVariables>;